import React from "react";

import ContactForm from "../../../../ContactForm";

const MA = ({ useFormalAddress, name = "marine" }) => {
    return (
        <ContactForm
            urlEnding="marine"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
            useFormalAddress={useFormalAddress}
        />
    );
};

export default MA;
